import React from "react";
import StatsPage from "../components/common/StatsPage";
import ServicesTable from "../components/webentries/services/ServicesTable";

const WebEntries = () => {
    return (
        <StatsPage
            title="Web Entries"
            pretitle="Entries"
            render={<ServicesTable></ServicesTable>}
        />
    );
};

export default WebEntries;
