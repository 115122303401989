/* eslint-disable react/prop-types */
import React from "react";
import DDISelector from "../DDISelector";
import APIKeySelector from "../APIKeySelector";
import { Button } from "react-bootstrap";
import ShortcodeSelector from "../ShortcodeSelector";
function CreateServicePopUp(props) {
    return (
        <div className="popup" style={{ zIndex: "2000" }}>
            <div
                className="d-flex content card align-items-center justify-content-around p-5"
                style={{ minWidth: "400px" }}
            >
                <div
                    className="position-absolute top-0 end-0 me-3 mt-2 fe fe-x-circle"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => props.onCloseClicked()}
                ></div>

                <div className="row mt-2">
                    <h3>Create New Service</h3>
                </div>
                <input
                    className="form-control p-2 m-2"
                    name="Name"
                    type="text"
                    placeholder="Service Name"
                />
                <div className="m-2 w-100 ">
                    <DDISelector
                        onSelectDDI={(d) => {
                            console.log(d);
                        }}
                    />
                </div>
                <div className="m-2 w-100">
                    <APIKeySelector
                        onSelectApiKey={(d) => {
                            console.log(d);
                        }}
                    />
                </div>
                <div className="m-2 w-100">
                    <ShortcodeSelector
                        onSelectShortcode={(d) => {
                            console.log(d);
                        }}
                    />
                </div>
                <div className="d-f w-100 d-flex align-items-center m-2">
                    <input
                        className="form-control p-2"
                        name="Keyword"
                        type="text"
                        placeholder="Keyword"
                    />
                </div>
                <Button className="mt-3" variant="primary">
                    Create
                </Button>
            </div>
        </div>
    );
}

export default CreateServicePopUp;
