/* eslint-disable react/prop-types */
import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import TableHeader from "../../common/TableHeader";

import KeywordDetailsPopUp from "./KeywordDetailsPopUp";

function ServiceInfoPopUp(props) {
    console.log(props);

    const [selectedKeywordRow, setSelectedKeywordRow] = React.useState();

    const routingRuleRows = props.data.serviceRoutingRules.map((e) => {
        console.log(e);
        return (
            <tr key={e.outcome}>
                <td>{e.outcome}</td>
                <td>{e.pbxRuleName}</td>
                <td>{e.dtype}</td>
                <td>{e.dnumber}</td>
            </tr>
        );
    });

    return (
        <div className="popup" style={{ zIndex: "2000", overflow: "scroll" }}>
            {selectedKeywordRow ? (
                <KeywordDetailsPopUp
                    selectedKeywordRow={selectedKeywordRow}
                    onCloseClicked={() => setSelectedKeywordRow()}
                ></KeywordDetailsPopUp>
            ) : (
                ""
            )}
            <div
                className="d-flex content card align-items-center justify-content-around p-5"
                style={{ minWidth: "50%" }}
            >
                <div
                    className="position-absolute top-0 end-0 me-3 mt-2 fe fe-x-circle"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => props.onCloseClicked()}
                ></div>
                <Container style={{ overflow: "scroll", maxHeight: "90vh" }}>
                    <Row>
                        <Col className="col-12 ">
                            <div className="w-100 d-flex align-items-center justify-content-around">
                                <h2>Service Details</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <Row className="w-100">
                                <div className="container-fluid tableContainer w-100">
                                    <div
                                        className="card"
                                        style={{ overflow: "auto" }}
                                    >
                                        <div className="card-header">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h4 className="card-header-title p-0">
                                                        Service Details
                                                    </h4>
                                                </div>
                                                <div
                                                    className="col d-flex"
                                                    style={{
                                                        alignContent: "end",
                                                        justifyContent: "end",
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                        <Table
                                            className="table table-sm table-nowrap card-table"
                                            responsive="sm"
                                        >
                                            <thead>
                                                <tr>
                                                    <TableHeader name="Service ID"></TableHeader>
                                                    <TableHeader name="Name"></TableHeader>
                                                    <TableHeader name="DDI"></TableHeader>
                                                    <TableHeader name="Shortcode"></TableHeader>
                                                    <TableHeader name="ApiKey"></TableHeader>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{props.data.id}</td>

                                                    <td>{props.data.name}</td>

                                                    <td>{props.data.ddi}</td>

                                                    <td>
                                                        {props.data.shortcode}
                                                    </td>

                                                    <td>
                                                        {props.data.apiKeyName}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Row>
                        </Col>
                        <Col className="col-12">
                            <Row className="w-100">
                                <div
                                    className="container-fluid tableContainer w-100"
                                    style={{
                                        maxHeight: "50vh",
                                        overflow: "scroll",
                                    }}
                                >
                                    <div
                                        className="card"
                                        style={{ overflow: "auto" }}
                                    >
                                        <div className="card-header">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h4 className="card-header-title p-0">
                                                        Keywords
                                                    </h4>
                                                </div>
                                                <div
                                                    className="col d-flex"
                                                    style={{
                                                        alignContent: "end",
                                                        justifyContent: "end",
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                        <Table
                                            className="table table-sm table-nowrap card-table"
                                            responsive="sm"
                                        >
                                            <thead>
                                                <tr>
                                                    <TableHeader name="Keyword"></TableHeader>
                                                    <TableHeader name="Active From"></TableHeader>
                                                    <TableHeader name="Routing Rules"></TableHeader>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {props.data.keywords.map(
                                                    (k) => {
                                                        return (
                                                            <tr
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                key={k.id}
                                                                onClick={() =>
                                                                    setSelectedKeywordRow(
                                                                        k
                                                                    )
                                                                }
                                                            >
                                                                <td>
                                                                    {k.keyword}
                                                                </td>
                                                                <td>
                                                                    {new Date(
                                                                        k.useFrom *
                                                                            1000
                                                                    ).toLocaleString()}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        k
                                                                            .keywordRouting
                                                                            .length
                                                                    }
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    {/* <Row className="mt-2 w-75">
                    <AddKeyWordComponent data={props.data} />
                </Row> */}

                    <Row className="w-100 mt-5">
                        <div className="container-fluid tableContainer w-100">
                            <div className="card" style={{ overflow: "auto" }}>
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h4 className="card-header-title p-0">
                                                Service Default Routing
                                            </h4>
                                        </div>
                                        <div
                                            className="col d-flex"
                                            style={{
                                                alignContent: "end",
                                                justifyContent: "end",
                                            }}
                                        ></div>
                                    </div>
                                </div>
                                <Table
                                    className="table table-sm table-nowrap card-table"
                                    responsive="sm"
                                >
                                    <thead>
                                        <tr>
                                            <TableHeader name="Outcome"></TableHeader>
                                            <TableHeader name="Rule"></TableHeader>
                                            <TableHeader name="DType"></TableHeader>
                                            <TableHeader name="DNumber"></TableHeader>
                                        </tr>
                                    </thead>

                                    <tbody>{routingRuleRows}</tbody>
                                </Table>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default ServiceInfoPopUp;
