import React from "react";
import { PropTypes } from "prop-types";
//import { DateTime } from 'luxon';
const ServiceRow = (props) => {
    // const startFormatted  =DateTime.fromSeconds(props.data.competitionWindowStartUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});
    // const endFormatted  =DateTime.fromSeconds(props.data.competitionWindowEndUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});
    // const entryTime  =DateTime.fromSeconds(props.data.entryTimeUnix).toFormat("DD HH:mm:ss ZZZZ",{zone:"Europe/London",locale:'en-GB'});
    return (
        <tr>
			<td>{props.data.id}</td>
            <td>{props.data.name}</td>
            <td>{props.data.ddi}</td>
            <td>{props.data.shortcode}</td>
            <td>{props.data.activeKeyword}</td>
            <td>{props.data.apiKeyName}</td>
            <td
            style={{cursor:'pointer'}} onClick={()=>props.onInfoClicked()}>INFO</td>
        </tr>
    );
};

ServiceRow.propTypes = {
    data: PropTypes.object.isRequired,
    onInfoClicked:PropTypes.func.isRequired
};

export default ServiceRow;
