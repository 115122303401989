/* eslint-disable react/prop-types */
import { useSelector } from "react-redux";
import axios from "../../axios/axios";
import { selectSelectedCustomer } from "../../rdx/slices/customerSlice";
import { useState } from "react";
import { useEffect } from "react";
import React from "react";
import PropTypes from "prop-types";
const DDISelector = (props) => {
    const selectedCustomer = useSelector(selectSelectedCustomer);

    const [ddis, setDdis] = useState();

    useEffect(() => {
        if (selectSelectedCustomer) {
            axios
                .get(
                    `/fonix-ivr-entries/customers/${selectedCustomer.id}/ddis?page=0&sort=ddi,asc`
                )
                .then((ok) => {
                    setDdis(ok.data);
                });
        }
    }, []);

    let render = <div></div>;

    if (ddis) {
        let ddiDivs = ddis.content.map((d) => {
            return (
                <option
                    key={d.ddi}
                    value={d.ddi}
                    disabled={d.available ? false : true}
                >
                    {d.ddi}
                </option>
            );
        });
        ddiDivs.unshift(
            <option key="0" value="0" disabled="disabled">
                DDI
            </option>
        );
        render = (
            <div className="d-flex align-items-center">
                {/* <h4 className="p-0 px-3 m-0">DDI</h4> */}
                <select
                    className="form-control p-2"
                    onChange={(e) => {
                        console.log(e);
                        props.onSelectDDI(e.target.value);
                    }}
                >
                    {ddiDivs}
                </select>
            </div>
        );
    }

    return <div>{render}</div>;
};

DDISelector.prototypes = {
    onSelectDDI: PropTypes.func,
};

export default DDISelector;
